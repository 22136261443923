import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from 'styled-components';

const IndexPage = () => {
//   var tag = document.createElement('script');
// tag.src = "https://www.youtube.com/iframe_api";
// var firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
 
// var player;
// function onYouTubeIframeAPIReady() {
//   player = new YT.Player('player', {
//     height: '360',
//     width: '640',
//     videoId: 'H2W0pD03Nxs',
//     playerVars: {
//       playsinline: 1 // 1はtrue、0はfalse
//     },
//     events: {
//       'onReady': onPlayerReady,
//       'onStateChange': onPlayerStateChange
//     }
//   });
// }
// function onPlayerReady(event) {
//   event.target.mute();
//   event.target.playVideo();
// }
// function onPlayerStateChange(event) {
//   if (event.data == YT.PlayerState.ENDED) {
//      event.target.playVideo();
//    }
//  }
  

  return(
  <Layout>
    <SEO title="Home" />
    <h1>Hi people!!!</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <ContentWrapper>
    testes
  <iframe title="aaa" width="853" height="480" src="//www.youtube.com/embed/d6SSnbVCmEg?autoplay=1&playsinline=1&mute=1" frameborder="0" allowfullscreen></iframe>
</ContentWrapper>
    <div id="player"></div>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
    <iframe title="bbb" width="560" height="315" src="https://www.youtube.com/embed/H2W0pD03Nxs?autoplay=1&mute=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
  </Layout>)
}

export default IndexPage

// // styled-compoenents
const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;

iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}
`
